/* eslint-disable no-useless-escape */
export const regexp = {
  address: /^([a-zA-z0-9/\\''(),-\s]{2,255})$/,
  date: /^2[0-9]{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  dateTime:
    /^2[0-9]{3}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  fullName: /^[a-zA-Z-0-9.,_]+ [a-zA-Z-0-9.,_]+$/,
  id: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  name: /^[a-zA-Z-0-9.,_ ]+$/,
  organisationName: /^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?&-]+$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
  phone: /^[0-9 ()+-]+$/,
  removeHtml: /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
  sectorId:
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  text: /^[A-Za-z0-9.,?!() ]+$/,
  vrm: /^[a-zA-Z0-9 ]*$/,
};
