import React from 'react';
import { DateRange } from 'react-day-picker';

import KdDayPickerView, { KdDayPickerDefaultProps } from './KdDayPickerView';
import useKdDayRangePicker from './hooks/useKdDayRangePicker';

interface Props extends Partial<KdDayPickerDefaultProps> {
  selected?: DateRange;
  mode?: 'range';
  onSelect: (range: DateRange | undefined) => void;
}

const KdDayRangePicker: React.FC<Props> = ({
  className,
  disabled = false,
  numberOfDaysBetween,
  onSelect,
  selected,
  testId,
  mode = 'range',
}) => {
  const { handleSelect, disabledDays } = useKdDayRangePicker({
    selected,
    onSelect,
  });

  return (
    <KdDayPickerView
      className={className}
      disabled={disabledDays || disabled}
      mode={mode}
      numberOfDaysBetween={numberOfDaysBetween}
      onDayClick={handleSelect}
      onSelect={onSelect}
      selected={selected}
      testId={testId}
      toDate={new Date()}
    />
  );
};

export default KdDayRangePicker;
