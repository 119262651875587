import { add, isEqual, max, sub, toDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { ActiveModifiers, DateRange } from 'react-day-picker';

const useKdDayRangePicker = ({
  onSelect,
  selected,
}: {
  onSelect?: (range: DateRange | undefined) => void;
  selected: DateRange | undefined;
}) => {
  const [dateIsEqual, setDateIsEqual] = useState<boolean>(false);

  const isDateEqual = (day: Date) =>
    isEqual(new Date(day), new Date(selected?.from!));

  const handleSelect = (day: Date, modifiers: ActiveModifiers) => {
    if (modifiers.disabled) {
      return;
    }

    if (isDateEqual(day)) {
      setDateIsEqual(true);
    } else {
      setDateIsEqual(false);
      handleDateAssignment(day);
    }
  };

  const handleDateAssignment = (day: Date) => {
    if (!selected?.from && !selected?.to) {
      onSelect?.({ from: day, to: undefined });
      return;
    }

    if (!selected?.from || !selected?.to) {
      onSelect?.({
        from: selected?.from ? selected.from : day,
        to: selected?.to ? selected.to : day,
      });
      return;
    }

    onSelect?.(
      day < selected.from
        ? { from: day, to: selected.to }
        : { from: selected.from, to: day }
    );
  };

  useEffect(() => {
    if (dateIsEqual) {
      onSelect?.(undefined);
    }
  }, [dateIsEqual, onSelect]);

  const hasBothDates = selected?.from && selected?.to;

  const currentlySelected = selected?.from ? selected?.from : selected?.to;

  const furthestAvailableDate = toDate(
    add(currentlySelected!, {
      months: 3,
    })
  );

  const today = new Date();
  const yesterday = toDate(today.setDate(today.getDate() - 1));

  const maxDate = hasBothDates
    ? max([selected?.from!, selected?.to!])
    : currentlySelected;

  const disabledDays = [
    {
      before: toDate(
        sub(maxDate!, {
          months: 3,
        })
      ),
      after: furthestAvailableDate,
    },
    {
      after: yesterday,
    },
  ];

  return {
    handleSelect,
    disabledDays,
    isDateEqual,
    onSelect,
    handleDateAssignment,
    dateIsEqual,
  };
};
export default useKdDayRangePicker;
